.home {
  max-width: 1200px;
  margin: 0 auto;

  & .section {
    display: flex;
    height: 600px;
    align-items: center;
    box-sizing: border-box;

    & .text-container {
      width: 700px;
      padding: 50px 50px 0 50px;
      box-sizing: border-box;
      position: relative;
      flex: 1;
    }

    & .image-container {
      width: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      & .code {
        position: absolute;
        width: 50px;
        top: 180px;
        left: 110px;
      }

      & .note {
        position: absolute;
        width: 50px;
        top: 180px;
        left: 380px;
      }

      & .coffee {
        position: absolute;
        top: 350px;
        left: 80px;
      }
    }

    & .main-title {
      font-size: 34px;
      font-weight: 600;
      color: var(--text-level-1);
      text-shadow: 0 5px 8px rgba(0, 0, 0, .3);
    }

    & .title {
      font-size: 30px;
      color: var(--text-level-1);
    }

    & .sub-title {
      margin-top: 30px;
      font-size: 22px;
      color: var(--text-level-3);
      font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      line-height: 30px;
    }

    img.view {
      width: 400px;
    }

    & .download-group {
      margin-top: 40px;

      & .btn {
        height: 40px;
        display: inline-flex;
        align-items: center;
        color: var(--background-level-1);
        background-color: var(--text-level-1);
        border-radius: 8px;
        padding: 0 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, .3);
        transition: all .2s ease-in-out;

        & .anticon {
          font-size: 26px;
        }

        & .text {
          display: flex;
          flex-direction: column;
          margin-left: 5px;
        }

        & .app-store {
          font-size: 14px;
          font-weight: 600;
        }

        & .download-text {
          font-size: 12px;
          font-weight: 600;
        }

        &:hover {
          background-color: var(--theme-color);
        }
      }
    }
  }

  & .footer {
    padding: 400px 0 400px 0;
    box-sizing: border-box;
    position: relative;

    & .email-icon {
      position: absolute;
      top: 350px;
      left: 50%;
      width: 40px;
      transform: translateX(-50%);
    }

    & .title {
      text-align: center;
      font-size: 20px;
      color: var(--text-level-1);
    }

    & .email {
      text-align: center;
      font-size: 16px;
      margin-top: 20px;
      color: var(--text-level-3);
    }
  }
}