.layout {
  overflow: auto;
  height: 100vh;

  & .header {
    height: 100px;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    & img.logo {
      width: 80px;
      height: 80px;
    }

    & .app-name {
      font-size: 28px;
      font-weight: 600;
      color: var(--text-level-1);
      margin-left: 5px;
      text-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    }
  }
}