:root {
  --theme-color: #6C63FF;
 }

 :root [data-theme=light] {
  --background-body: #f2f3f5;
  --background-level-1: #fff;
  --background-level-2: #f6f7f8;
  --background-level-3: #f2f3f5;
  --background-level-4: #e5e6e9;
  --background-level-5: #babdc5;
  --text-level-1: #121316;
  --text-level-2: #23252a;
  --text-level-3: #696f7d;
  --text-level-4: #9ea3ad;
  --text-level-5: #babdc5;
  --nav-text-level-1: #121316;
  --nav-text-level-2: #828996;
  --nav-text-level-3: #9ea3ad;
  --nav-background-level-1: #fff;
  --nav-background-level-2: #e5e6e9;
  --nav-icon-inactive: #3a3e45;
  --nav-hotkey-bg: #e5e6e9;
  --nav-hotkey-bg-active: #f2f3f5;
  --nav-hotkey-text: #7a819b;
  --delete-text: #fa541c;
}

:root [data-theme=dark] {
  --background-body: #121316;
  --background-level-1: #1c1d21;
  --background-level-2: #23252a;
  --background-level-3: #2e3237;
  --background-level-4: #2e3237;
  --text-level-1: #e5e6e9;
  --text-level-2: #babdc5;
  --text-level-3: #828996;
  --text-level-4: #696f7d;
  --text-level-5: #525761;
  --nav-text-level-1: #e5e6e9;
  --nav-text-level-2: #9ea3ad;
  --nav-text-level-3: #696f7d;
  --nav-background-level-1: #23252a;
  --nav-background-level-2: #1c1d21;
  --nav-icon-inactive: #9ea3ad;
  --nav-hotkey-bg: #23252a;
  --nav-hotkey-bg-active: #1c1d21;
  --nav-hotkey-text: #828996;
  --delete-text: #d84a1b;
}

html {
  overflow: hidden;
}

body {
  background-color: var(--background-body);
  font-family: SC, Microsoft YaHei, ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol;
}

a {
  color: inherit;
  text-decoration: none;
}